<template>
  <div
    id="users"
    class="page-container-table"
  >
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="''"
      :hideAdd="false"
      :arrayExcel="[]"
      :hideDowload="false"
      :hideExportFile="false"
      :hideImportFile="false"
      :hideDelete="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="()=>{}"
      @clickDowloadSample="()=>{}"
      @clickExportExcel="()=>{}"
      @importFile="()=>{}"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: số hiệu văn bản-->

        <!-- Column:Chức năng -->
        <span v-if="props.column.field === 'ActionFunction'">
          <a
            :href="$serverfile + props.row.filePath"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Tải xuống'"
              icon="DownloadIcon"
              size="18"
              class="text-body"
            />
          </a>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

  </div>
</template>

<script>
import {
  BBadge,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    MyPagination,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    BButton,
    VBTooltip,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
  },
  data() {
    return {
      arrayExcel: [],
      columns: [
        {
          label: 'Tên công văn',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Tên tệp',
          field: 'fileName',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalType: '',
      totalRecord: 0,
      dataDetail: {},
      idDetail: '',
      modalIdCreate: 'modal-id-create-use-device',
      modalIdEdit: 'modal-id-edit-use-device',
      dataList: [],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
